'use client'
import {useEffect} from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPerformance from "@bugsnag/browser-performance";
import useGebruiker from "hooks/useGebruiker";

export const BugsnagClient = ({ Component, pageProps, err }) => {
    const {ingelogdeGebruiker} = useGebruiker()
    useEffect(() => {
        if (!Bugsnag._client) {
            // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
            Bugsnag.start({
                apiKey: process.env.NEXT_PUBLIC_BUGSNAG_APIKEY,
                appVersion: process.env.NEXT_PUBLIC_VERSIE,
                releaseStage: (process.env.NEXT_PUBLIC_APP_ENV),
                enabledReleaseStages: ['production', 'testing', 'local'],
                onError: function (event) {
                    if (
                        [401, 403, 404, 419, 422].includes(event.originalError.response?.status)
                        || (event.errors[0]?.errorClass === 'InvalidError' && event.errors[0]?.errorMessage === 'unhandledrejection handler received a non-error. See "unhandledrejection handler" tab for more detail.')
                    ) return false;
                }
            })


            BugsnagPerformance.start({
                apiKey: process.env.NEXT_PUBLIC_BUGSNAG_APIKEY,
                appVersion: process.env.NEXT_PUBLIC_VERSIE,
                releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
                enabledReleaseStages: ['production', 'testing', "local"],
            });
        }
    }, [err]);

    useEffect(() => {
        if (ingelogdeGebruiker) {
            Bugsnag.setUser(ingelogdeGebruiker.id, ingelogdeGebruiker.naam, ingelogdeGebruiker.email)
        } else {
            Bugsnag.setUser("Niet Ingelogd")
        }
    }, [ingelogdeGebruiker])

    return <></>
}