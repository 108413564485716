"use client"

import React, {useState, Suspense} from 'react'
import style from "./hamburgeroverlaymenu.module.scss";
import {GiHamburgerMenu} from "react-icons/gi";
import Link from "next/link";
import Image from 'next/image'
import {useTekstClient} from "hooks/useTekstClient";




export default function HamburgerOverlayMenu({isMkgEu}) {

    const [showOverlayMenu, setShowOverlayMenu] = useState(false)
    const tekst = useTekstClient()

    const vertalingArr = [
        {taal: 'NL', img_path: '/media/lang-nl-icon.png'},
        {taal: 'DE', img_path: '/media/lang-de-icon.png'},
        {taal: 'EN', img_path: '/media/lang-en-icon.png'}
    ]

    function handleClose() {
        setShowOverlayMenu(false)
    }


    return <div className={style.hamburgerMenuContainer}>
        {isMkgEu ? <div onClick={() => setShowOverlayMenu(true)} className={`${style.hamburger}`}>
            <GiHamburgerMenu/>
        </div> : <div onClick={() => setShowOverlayMenu(true)} className={`${style.menuItem} asLink`}>{tekst('algemeen:menu:Menu')}</div>}

            <div className={`${style.overlayMenu} ${showOverlayMenu ? style.overlayMenuShow : ''}`}>
                {showOverlayMenu && <div className={style.menuContainer}>


                    <div className={`${style.menuClose}`} onClick={handleClose}/>


                    <ul className={style.ulMainNav}>
                        <li>
                            <Link href={'/public'} onClick={handleClose}>
                                {tekst('mkgeu:menu:hamburger:home:linktekst')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/mkg3-erp-software'} onClick={handleClose}>
                                {tekst('mkgeu:menu:hamburger:mkg3erp:linktekst')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/mkg5-erp-systeem'} onClick={handleClose}>
                                {tekst('mkgeu:menu:hamburger:mkg5erp:linktekst')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/mkg5-erp-pakket'} onClick={handleClose}>
                                {tekst('mkgeu:menu:hamburger:mkg5erpsysteem:linktekst')}
                            </Link>
                        </li>
                        <li>
                            <Link href={'/mkg-add-ons'} onClick={handleClose}>
                                {tekst('website:menu:addons')}
                            </Link>
                        </li>
                        <li>
                            <Link href={'/over-mkg'} onClick={handleClose}>
                                {tekst('mkgeu:menu:hamburger:overmkg:linktekst')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/over-mkg#vacatures'} onClick={handleClose}>
                                {tekst('website:menu:vacatures')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/mijn-mkg/support'}
                                  onClick={handleClose}>
                                {tekst('website:menu:support')}
                            </Link>
                        </li>

                        <li>
                            <Link href={'/mijn-mkg'} onClick={handleClose}>
                                {tekst('website:menu:mijnmkg')}
                            </Link>
                        </li>

                        <li>
                            <div id="vc-lang" className={style.vertalingContainer}>
                                {vertalingArr.map(vertaling => {
                                    return <span key={`vertaling_a_${vertaling.taal.toLowerCase()}`}
                                                 onClick={function () {
                                                     document.cookie = 'locale=; path=/; domain=.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                                     document.cookie = 'locale=; path=/; domain=.www.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                                     document.cookie = 'locale=; expires=Thu, 01 Jan 1970 00:00:01 GMT';
                                                     window.location.href = `${process.env.NEXT_PUBLIC_BACKEND}/locale/${vertaling.taal.toLowerCase()}`;
                                                 }}
                                                 className={`${style.itemLangShort}`}>
                                    <Image
                                        src={vertaling.img_path}
                                        width={32}
                                        height={24}
                                        alt="Picture of a country flag"
                                    />
                                                <div>{vertaling.taal}</div>
                                            </span>
                                })}
                            </div>
                        </li>
                    </ul>


                    <div className={style.menuBottom}>
                        <ul>
                            <li><Link disabled href={'/disclaimer'} onClick={handleClose}>
                                Disclaimer
                            </Link></li>
                            <li><Link disabled href={'/sitemap'} onClick={handleClose}>
                                Sitemap
                            </Link></li>
                            <li><Link disabled href={'/privacy'} onClick={handleClose}>
                                Privacy
                            </Link></li>
                        </ul>
                        <div>
                            {tekst('website:menu:submenu:ondertekst')}
                        </div>
                    </div>


                </div>}

            </div>
        </div>
}

