"use client"

import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import {IoMdArrowDropdown} from "react-icons/io";
import Link from "next/link";
import React, {useEffect, useState} from "react";
import {useAxios} from "hooks/useAxios";
import SelectBedrijven from "components/SelectAlleBedrijven/SelectBedrijven/SelectBedrijven";
import {useTekstClient} from "hooks/useTekstClient";
import style from './gebruiker-dropdown.module.scss'
import {useRouter} from 'next/navigation'
import useGebruiker from "hooks/useGebruiker";
import {useSWRConfig} from "swr"
import revalidateAction from "../../../lib/actions";

export const GebruikerDropDown = () => {
    const {mutate} = useSWRConfig()
    const {ingelogdeGebruiker, namensBedrijf} = useGebruiker()
    const {get, post} = useAxios();
    const router = useRouter();
    const tekst = useTekstClient();
    const [bedrijven, setBedrijven] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        get(`${process.env.NEXT_PUBLIC_BACKEND}/api/bedrijven`)
            .then(response => {
                setBedrijven(response.data.data)
            });
    }, [])

    const handleSaveBedrijf = bedrijf => {
        if (bedrijf != null) {
            post(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf/` + bedrijf.id).then(response => {
                if (response.status === 200) {
                    mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`)
                    revalidateAction('namensBedrijf')
                    setMenuOpen(false)
                }
            })
        } else {
            post(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf/unset`).then(response => {
                if ([200, 204].includes(response.status)){
                    mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`)
                    revalidateAction('namensBedrijf')
                }
            })
        }
    }

    const uitloggen = async () => {
        const logOff = await post(`${process.env.NEXT_PUBLIC_BACKEND}/api/uitloggen`)
        const {status} = logOff
        if (status === 204) {
            router.push('/mijn-mkg/support');
            router.refresh();
        }
    }

    return <Menu as="div" className="relative inline-block text-left">
        <div onClick={() => setMenuOpen(!menuOpen)}>
            <MenuButton
                className="inline-flex w-full justify-center gap-x-1.5 px-1 py-2 text-xs uppercase ">

                <section className={`flex justify-center items-center ${style.ingelogdeGebruikerContainer}`}>
                    {ingelogdeGebruiker && <div>{ingelogdeGebruiker.naam}</div>}
                    {namensBedrijf && <div>|</div>}
                    {namensBedrijf && <div>{namensBedrijf.naam}</div>}
                </section>


                <IoMdArrowDropdown aria-hidden="true" className="-mr-1 h-5 w-5"/>
            </MenuButton>
        </div>

        {menuOpen && <MenuItems
            static={true}
            className="z-[999999] absolute right-0 z-10 mt-2 py-2 w-80 origin-top-right  bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
            <div className="py-1 uppercase text-xs">
                <MenuItem onClick={() => setMenuOpen(false)}>
                    <Link
                        href={`/mijn-mkg/mijn-gegevens/gebruikersbeheer/account/${ingelogdeGebruiker.id}`}
                        className="block px-4 py-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                        Account
                    </Link>
                </MenuItem>
                {ingelogdeGebruiker.groep.mkger &&
                <MenuItem onClick={() => setMenuOpen(false)}>
                    <Link
                        href="/mijn-mkg/instellingen"
                        className="block px-4 py-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                        {tekst('pagina:menu:Instellingen')}
                    </Link>
                </MenuItem>
                }

                <MenuItem className={`hover:text-cyan`} onClick={() => {
                    uitloggen()
                    setMenuOpen(false)

                }}>
                    <small
                        className="block w-full px-4 py-1 text-left text-xs text-gray-700 data-[focus]:text-kleur-cyan2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 uppercase underline cursor-pointer"
                    >
                        {tekst('pagina:menu:uitloggen')}
                    </small>
                </MenuItem>

                <section className={`my-2`}>
                    <div
                        className={`block px-4 py-3 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 border-y border-inherit`}>
                        <p>{tekst('algemeen:menu:inloggen_namens')}</p>

                        <SelectBedrijven
                            getOptionValue={option => option.id} // value is bedrijf, dus hier id
                            value={namensBedrijf}
                            options={bedrijven}
                            isLoading={!bedrijven}
                            isMulti={false}
                            isClearable={(ingelogdeGebruiker !== null
                                && ingelogdeGebruiker.hasOwnProperty('groep')
                                && ingelogdeGebruiker.groep.mkger)
                            }
                            onChange={handleSaveBedrijf}
                        />

                    </div>
                </section>
                {namensBedrijf && namensBedrijf != "" ?
                <MenuItem onClick={() => setMenuOpen(false)}>
                    <Link
                        href="/mijn-mkg/mijn-gegevens/producten/daglicentie"
                        className="block px-4 pt-1 !pb-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                        {tekst('pagina:menu:daglicentie ophalen')}
                    </Link>
                </MenuItem>
                    :
                    <MenuItem disabled>
                        <p
                            className="block px-4 pt-1 !pb-1 text-xs text-slate-700 underline opacity-25"
                        >
                            {tekst('pagina:menu:daglicentie ophalen')}
                        </p>
                    </MenuItem>
                }
            </div>
        </MenuItems>}
    </Menu>
}