import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';


import 'dayjs/locale/nl';


dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);


dayjs.locale('nl');

export const dayJs = dayjs

export function formatDate(date, format) {
    return dayjs(date).format(format);
}

export function getLaatsteVersie(product,producten){
    const p = producten.find(x => x.id === product)
    const {versies} = p
    const laatsteVersie = versies.at(0).nr

    return laatsteVersie?.split('.').slice(0, 2).join('.')
}