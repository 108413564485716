"use client"
import React from 'react'
import Link from "next/link";
import Image from "next/image";
import style from "./mijnmkgsubmenu.module.scss";
import {usePathname} from 'next/navigation';
import {useTekstClient} from "hooks/useTekstClient";
import {dayJs} from "../../../lib/functions/helperFunctions";
import useGebruiker from "hooks/useGebruiker";


export const MijnMkgSubMenu = () => {

    const pathname = usePathname()
    const tekst = useTekstClient()
    const {namensBedrijf, rechten, rechtenIsLoading, ingelogdeGebruiker, ingelogdeGebruikerIsLoading} = useGebruiker()
    const instellingUren = ingelogdeGebruiker?.instellingen?.find(i => i.id === 6)
    const urenInstellingId = instellingUren?.gebruikers_instelling?.ingestelde_optie
    const isMkger = ingelogdeGebruiker?.groep.mkger

    return <section style={{maxWidth: '1140px'}} className={`container mx-auto flex-auto justify-center h-[60px]`}>

        <ul className={`h-full flex font-bold no-underline`}>
            {/*Dashboard*/}
            <li className={`w-60 ${style.menuListItem} ${pathname === '/mijn-mkg/dashboard' ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/dashboard`} style={{textDecoration: 'none'}}
                      className={`w-[60px] h-full flex justify-center items-center`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/Dashboard/icons/home.svg"
                        width={20}
                        height={20}
                        alt="icon of dashboard"
                    />
                </Link>
            </li>
            {/*Mijn Gegevens*/}
            {!namensBedrijf || rechtenIsLoading || (!isMkger && !rechten?.some(x => x.codenaam === 'BEDRIJF_GEGEVENS' && x.handeling.lezen))
                ?
                <li className={`w-full ${style.menuListItem} ${style.disabled}`}>
                    <p style={{textDecoration: 'none'}} disabled={true}
                          className={`flex justify-center items-center w-full h-full gap-2 hover:no-underline`}>
                        <Image
                            className={style.menuIcon}
                            src="/media/Hoofdmenu/mijn-gegevens.svg"
                            width={20}
                            height={20}
                            alt="icon of mijn gegevens"
                        />
                        <span className={style.buttonText}>{tekst('algemeen:menu:Mijn gegevens')}</span>
                    </p>
                </li>
                :
                <li className={`w-full ${style.menuListItem} ${pathname.includes('/mijn-mkg/mijn-gegevens') ? style.menuListItemActive : ''}`}>
                    <Link href={`/mijn-mkg/mijn-gegevens`} style={{textDecoration: 'none'}}
                          className={`flex justify-center items-center w-full h-full gap-2 hover:no-underline`}>
                        <Image
                            className={style.menuIcon}
                            src="/media/Hoofdmenu/mijn-gegevens.svg"
                            width={20}
                            height={20}
                            alt="icon of mijn gegevens"
                        />
                        <span className={style.buttonText}>{tekst('algemeen:menu:Mijn gegevens')}</span>
                    </Link>
                </li>
            }
            {/*Bedrijfsdoelen*/}
            {!namensBedrijf
            || rechtenIsLoading || ( !isMkger && !rechten?.some(x => x.codenaam === 'BEDRIJFSDOELEN' && x.handeling.lezen))
                ?
                <li className={`w-full ${style.menuListItem} ${style.disabled}`}>
                    <p
                        style={{textDecoration: 'none'}}
                        className={`flex justify-center items-center w-full h-full gap-2`}>
                        <Image
                            className={style.menuIcon}
                            src="/media/Hoofdmenu/bedrijfsdoelen.svg"
                            width={20}
                            height={20}
                            alt="icon of bedrijfsdoelen"
                        />
                        <span className={style.buttonText}> {tekst('algemeen:menu:Bedrijfsdoelen')}</span>
                    </p>
                </li>
                :
                <li className={`flex justify-center items-center w-full ${style.menuListItem} ${pathname.includes('mijn-mkg/bedrijfsdoelen') ? style.menuListItemActive : ''}`}>
                    <Link
                        href={{pathname: `/mijn-mkg/bedrijfsdoelen`, query: {tab: "implementatieplan"}}}
                        style={{textDecoration: 'none'}}
                        className={`flex justify-center items-center w-full h-full gap-2`}>
                        <Image
                            className={style.menuIcon}
                            src="/media/Hoofdmenu/bedrijfsdoelen.svg"
                            width={20}
                            height={20}
                            alt="icon of bedrijfsdoelen"
                        />
                        <span className={style.buttonText}> {tekst('algemeen:menu:Bedrijfsdoelen')}</span>
                    </Link>
                </li>
            }
            {/*Academy*/}
            <li className={`flex justify-center items-center w-full ${style.menuListItem} ${pathname.includes('/mijn-mkg/academy') ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/academy`} style={{textDecoration: 'none'}}
                      className={`flex justify-center items-center w-full h-full gap-2`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/Hoofdmenu/academy.svg"
                        width={20}
                        height={20}
                        alt="icon of academy"
                    />
                    <span className={style.buttonText}>{tekst('algemeen:menu:Academy')}</span>
                </Link>
            </li>

            {/*Activiteiten*/}
            <li className={`flex justify-center items-center w-full ${style.menuListItem} ${pathname.includes('/mijn-mkg/activiteiten') ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/activiteiten`} style={{textDecoration: 'none'}}
                      className={`flex justify-center items-center w-full h-full gap-2`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/Hoofdmenu/activiteiten.svg"
                        width={20}
                        height={20}
                        alt="icon of activiteiten"
                    />
                    <span className={style.buttonText}>{tekst('algemeen:menu:Activiteiten')}</span>
                </Link>
            </li>

            {/*Support*/}
            <li className={`flex justify-center items-center w-full ${style.menuListItem} ${pathname.includes('/mijn-mkg/support') ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/support`} style={{textDecoration: 'none'}}
                      className={`flex justify-center items-center w-full h-full gap-2`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/Hoofdmenu/support.svg"
                        width={20}
                        height={20}
                        alt="icon of support"
                    />
                    <span className={style.buttonText}>{tekst('algemeen:menu:Support')}</span>
                </Link>
            </li>

            {/*Uren*/}
            {isMkger &&
            <li className={`w-60 ${style.menuListItem} ${pathname.includes(`/mijn-mkg/uren`) ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/uren?date=${dayJs().format('YYYY-MM-DD')}&period=${urenInstellingId === 0 ? 'day' : urenInstellingId === 1 ? 'week' : 'month'}`} className={`w-[60px] h-full flex justify-center items-center`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/person_time_ico.svg"
                        width={20}
                        height={20}
                        alt="icon uren"
                    />
                </Link>
            </li>
            }
            {/*Beheer*/}
            {isMkger &&
            <li className={`w-60 ${style.menuListItem} ${pathname.includes('/mijn-mkg/beheer') ? style.menuListItemActive : ''}`}>
                <Link href={`/mijn-mkg/beheer`} className={`w-[60px] h-full flex justify-center items-center`}>
                    <Image
                        className={style.menuIcon}
                        src="/media/Hoofdmenu/beheer.svg"
                        width={20}
                        height={20}
                        alt="icon uren"
                    />
                </Link>
            </li>
            }
        </ul>
    </section>
}