"use client";
import react from "react";
import styles from './footer-dropdown.module.scss'
import {Dropdown} from "react-bootstrap";
import {FaGlobeAmericas} from "@react-icons/all-files/fa/FaGlobeAmericas";
import {useAxios} from "hooks/useAxios";
const FooterDropdown = ({land, landen}) => {
    const {post} = useAxios()
    const handleChangeLand = (land_id) => {
        document.cookie = 'land=; path=/; domain=.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = 'land=; path=/; domain=.www.mkg.eu; expires=Thu, 01 Jan 1970 00:00:01 GMT';
        document.cookie = 'land=; expires=Thu, 01 Jan 1970 00:00:01 GMT';


            post(`${process.env.NEXT_PUBLIC_BACKEND}/api/land`, {'land_id': land_id})
            .then(() => {
                location.reload();
            });
    }

    return (
        <div className={styles.customDropdownContainer}>
            <FaGlobeAmericas className={styles.globeIcon}/>
            <select
                className={styles.customDropdown}
                value={land?.id}
                onChange={(e) => handleChangeLand(e.target.value)}
            >
                {landen?.map(x => (
                    <option
                        key={`landdrpdwn_${x.id}`}
                        value={x.id}
                    >
                        {x.naam}
                    </option>
                ))}
            </select>
        </div>

        //     <Dropdown className={`${style.landen} mt-3`}>
        //     <Dropdown.Toggle variant="Secondary" id="dropdown-basic" className={'pl-0'}>
        //         { land?.naam } <FaGlobeAmericas style={{margin: '0 4px 4px 4px'}} />
        //     </Dropdown.Toggle>
        //
        //     <Dropdown.Menu>
        //         { landen?.map(x => <Dropdown.Item key={`landdrpdwn_${x.id}`} active={land?.id === x.id}
        //                                           onClick={() => handleChangeLand(x.id)}
        //                                           className={style.landItem} eventKey={x.id}>{x.naam}</Dropdown.Item>)}
        //     </Dropdown.Menu>
        // </Dropdown>
    )
}

export default FooterDropdown